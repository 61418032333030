import React, { useEffect } from "react";

function App() {
  const newUrl = `${window.location.toString().replace("beta.", "")}`;
  const navigateToLive = () => {
    console.log(`navigating to ${newUrl}`);
    window.location.href = newUrl;
  };

  useEffect(() => {
    navigateToLive();
    // eslint-disable-next-line
  }, []);
  return <div className="App"></div>;
}

export default App;
